import React from 'react';
import PropTypes from 'prop-types';
import useTranslation from 'next-translate/useTranslation';
import useHint from '../hooks/useHint';
import { Style } from './styles';

function Field({
  value,
  placeholder,
  name,
  maxLength,
  type,
  disabled,
  error,
  area,
  onChange,
  onBlur,
  flex,
  min,
  max,
  step,
  onKeyPress,
  eventType = false,
  darkTheme,
  label,
  description,
  rows,
  required,
  height = '',
  background,
  id,
  phoneCode = '',
  field,
  form,
  icon,
  constructorField,
  formik,
  changeErrorUi,
  errorClass,
}) {
  const { t } = useTranslation();
  const fieldChange = (e) => {
    if (formik) return field.onChange(e);
    eventType ? onChange(e) : onChange(e.target.value);
  };
  const [wrapperRef, Hint] = useHint(disabled);
  const [visible, setVisible] = React.useState(false);

  const commonProps = {
    onBlur,
    name,
    placeholder,
    readOnly: disabled,
    disabled,
    error,
    onKeyPress,
    'data-theme': darkTheme ? 'dark' : 'light',
    onChange: fieldChange,
  };
  return (
    <React.Fragment>
      {Hint()}
      <Style.Wrap
        height={height}
        flex={flex}
        ref={wrapperRef}
        area={area}
        constructorField={constructorField}
        withLabel={Boolean(label)}>
        {!!label && <Style.Label required={required}>{label}</Style.Label>}
        {!!description && <Style.Description>{description}</Style.Description>}
        {area ? (
          <Style.Area
            background={background}
            value={value}
            height={height}
            maxLength={maxLength}
            rows={rows}
            {...commonProps}
            {...field}
            onBlur={() => {
              typeof onBlur === 'function' && onBlur();
            }}
          />
        ) : type === 'password' ? (
          <>
            <Style.Input
              background={background}
              type={visible ? 'text' : 'password'}
              value={type === 'number' && Number.isNaN(+value) ? '' : value}
              maxLength={maxLength}
              min={min}
              max={max}
              height={height}
              icon={icon}
              step={step}
              {...commonProps}
              {...field}
            />
            <Style.IconContainer
              onClick={() => {
                setVisible((prev) => !prev);
                setTimeout(() => {
                  setVisible(false);
                }, 3000);
              }}>
              {visible ? <Style.PasswordIconVisible /> : <Style.PasswordIconHidden />}
            </Style.IconContainer>
          </>
        ) : (
          type !== 'tel' &&
          type !== 'telCode' && (
            <Style.Input
              background={background}
              type={type}
              value={type === 'number' && Number.isNaN(+value) ? '' : value}
              maxLength={maxLength}
              min={min}
              max={max}
              height={height}
              icon={icon}
              step={step}
              {...commonProps}
              {...field}
              onBlur={() => {
                typeof onBlur === 'function' && onBlur();
              }}
            />
          )
        )}
        {/*{type === 'telCode' && (*/}
        {/*  <PhoneInput*/}
        {/*    type={type}*/}
        {/*    inputStyle={PhoneStyle}*/}
        {/*    dropdownStyle={{ display: 'none' }}*/}
        {/*    buttonStyle={{ display: 'none' }}*/}
        {/*    containerStyle={{ width: '100%', height: `${height ? height : 0}` }}*/}
        {/*    country={phoneCode.toLowerCase()}*/}
        {/*    value={value}*/}
        {/*    {...commonProps}*/}
        {/*    {...field}*/}
        {/*  />*/}
        {/*)}*/}
        {type === 'tel' && (
          <Style.Input
            id={id}
            background={background}
            type={type}
            value={type === 'number' && Number.isNaN(+value) ? '' : value}
            min={min}
            max={max}
            height={height}
            step={step}
            mask={type === 'tel' ? '+99(999) 99 99 999' : false}
            {...commonProps}
            {...field}
          />
        )}
        {!!error && (
          <Style.Error className={errorClass ? errorClass : ''} changeErrorUi={changeErrorUi}>
            {t(error, error)}
          </Style.Error>
        )}
        {formik && !error && form?.touched[field.name] && !!form?.errors[field.name] && (
          <Style.Error className={errorClass ? errorClass : ''} changeErrorUi={changeErrorUi}>
            {t(form.errors[field.name], form.errors[field.name])}
          </Style.Error>
        )}
      </Style.Wrap>
    </React.Fragment>
  );
}

Field.propTypes = {
  value: PropTypes.any,
  maxLength: PropTypes.number,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.any,
  description: PropTypes.string,
  required: PropTypes.bool,
  flex: PropTypes.number,
  min: PropTypes.number,
  rows: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  disabled: PropTypes.bool,
  error: PropTypes.any,
  icon: PropTypes.string,
  area: PropTypes.bool,
  onBlur: PropTypes.func,
  onKeyPress: PropTypes.func,
  eventType: PropTypes.bool,
  darkTheme: PropTypes.bool,
  height: PropTypes.string,
  background: PropTypes.string,
  id: PropTypes.string,
  constructorField: PropTypes.bool,
  phoneCode: PropTypes.string,
  field: PropTypes.shape({
    name: PropTypes.string,
    onChange: PropTypes.func,
  }),
  form: PropTypes.shape({
    touched: PropTypes.object,
    errors: PropTypes.object,
  }),
  formik: PropTypes.any,
  changeErrorUi: PropTypes.bool,
};

export { Field };
