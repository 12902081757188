import * as React from 'react';

const PasswordEye = React.memo(() => {
  return (
    <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9 1.5C11.8425 1.5 14.3775 3.0975 15.615 5.625C14.3775 8.1525 11.8425 9.75 9 9.75C6.1575 9.75 3.6225 8.1525 2.385 5.625C3.6225 3.0975 6.1575 1.5 9 1.5ZM9 0C5.25 0 2.0475 2.3325 0.75 5.625C2.0475 8.9175 5.25 11.25 9 11.25C12.75 11.25 15.9525 8.9175 17.25 5.625C15.9525 2.3325 12.75 0 9 0ZM9 3.75C10.035 3.75 10.875 4.59 10.875 5.625C10.875 6.66 10.035 7.5 9 7.5C7.965 7.5 7.125 6.66 7.125 5.625C7.125 4.59 7.965 3.75 9 3.75ZM9 2.25C7.14 2.25 5.625 3.765 5.625 5.625C5.625 7.485 7.14 9 9 9C10.86 9 12.375 7.485 12.375 5.625C12.375 3.765 10.86 2.25 9 2.25Z"
        fill="#A5B3C5"
      />
    </svg>
  );
});
export { PasswordEye };
