import { useRef, useEffect, useState } from 'react';
import { Wrapper, Hint } from './style';

const useHint = (disabled, stateOpen) => {
  const [positionHint, changePositionHint] = useState({ clientX: 0, clientY: 0, display: 'none' });
  const wrapperRef = useRef(null);

  const clickOutside = (event) => {
    if (stateOpen && wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      stateOpen(false);
    }
  };

  const listenMoveMouse = ({ clientX, clientY }) => {
    changePositionHint({ clientX: clientX + 4, clientY: clientY + 2, display: 'block' });
  };

  const listenEnterMouse = (event) => {
    switch (event.type) {
      case 'mouseover': {
        document.addEventListener('mousemove', listenMoveMouse);
        break;
      }
      case 'mouseleave': {
        document.removeEventListener('mousemove', listenMoveMouse);
        changePositionHint({ clientX: 0, clientY: 0, display: 'none' });
        break;
      }
      default:
        break;
    }
  };

  useEffect(() => {
    if (disabled) {
      wrapperRef.current.addEventListener('mouseover', listenEnterMouse);
      wrapperRef.current.addEventListener('mouseleave', listenEnterMouse);
    } else {
      document.addEventListener('mousedown', clickOutside);
    }
    return () => {
      if (disabled) {
        wrapperRef.current.removeEventListener('mouseover', listenEnterMouse);
        wrapperRef.current.removeEventListener('mouseleave', listenEnterMouse);
      } else {
        document.removeEventListener('mousedown', clickOutside);
      }
    };
  }, []);

  const Hints = () =>
    disabled && (
      <Wrapper style={{ top: positionHint.clientY, left: positionHint.clientX, display: positionHint.display }}>
        <Hint>Данное поле невозможно заполнить</Hint>
      </Wrapper>
    );

  return [wrapperRef, Hints];
};

export default useHint;
