import styled from 'styled-components';
import InputMask from 'react-input-mask';
import { variables } from '@assets/style/tools/variables';
import { appearanceNone, customPlaceholder, flexCenter, setSize, transition } from '@assets/style/tools/mixin';
import { PasswordEyeCrossed } from '@icons/Svg/PasswordEyeCrossed';
import { PasswordEye } from '@icons/Svg/PasswordEye';

const Style = {
  Wrap: styled.label`
    display: grid;
    grid-template-rows: ${(props) => (props.constructorField === true ? `auto 36px` : `auto 48px`)};
    position: relative;
    grid-row-gap: 10px;
    align-content: center;
    z-index: ${(props) => (props.zIndex ? props.zIndex : props.open ? '2' : '1')};
    ${(props) => (props.area || props.height ? `grid-template-rows: auto auto;` : ``)};
    ${(props) => (props.withLabel ? `` : `grid-row-gap: initial;`)};

    input {
      ::-moz-placeholder {
        /* Firefox 19+ */
        ${(props) => (props.constructorField === true ? `line-height: 36px;` : `line-height: 48px;`)}
      }

      :-ms-input-placeholder {
        /* IE 10+ */
        ${(props) => (props.constructorField === true ? `line-height: 36px;` : `line-height: 48px;`)}
      }
      :-moz-placeholder {
        /* Firefox 18- */
        ${(props) => (props.constructorField === true ? `line-height: 36px;` : `line-height: 48px;`)}
      }
    }
    .phone-input {
      color: white;
      border: 1px solid rgba(255, 255, 255, 0.2);
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 2px;
      ${customPlaceholder(`
        color: #DDE4EA;
        font-weight: normal;
      `)}
      &:hover {
        border-color: #a5b3c5;
      }
    }
  `,
  Label: styled.span`
    position: relative;
    width: 100%;
    display: flex;
    font-weight: 600;
    font-size: 13px;
    line-height: 1.15 !important;
    color: ${variables.black};
    &::before {
      content: '*';
      display: ${(props) => (props.required ? 'inline-block' : 'none')};
      font-size: 14px;
      color: ${variables.red};
      margin-right: 3px;
    }
  `,
  Description: styled.p`
    font-size: 12px;
    line-height: 140%;
    color: ${variables.black};
  `,
  Input: styled(InputMask)`
    display: block;
    grid-row-start: 2;
    width: 100%;
    height: ${(props) => (props.height ? props.height : '100%')};
    font-size: ${(props) => (props.height ? '13px' : '17px')};
    font-weight: 500;
    color: ${(props) => (props['data-theme'] === 'dark' ? 'white' : variables.black)};
    padding: 0 15px;
    border-radius: 2px;
    border: 1px solid
      ${(props) => {
        if (props.border) return 'red';
        return props.disabled
          ? '#DDE4EA'
          : props['data-theme'] === 'dark'
          ? 'rgba(255, 255, 255, 0.2)'
          : variables.border;
      }};
    cursor: ${(props) => (props.disabled ? 'pointer' : 'initial')};
    background-color: ${(props) =>
      props['data-theme'] === 'dark' ? `rgba(255, 255, 255, 0.1)` : props.background || 'white'};
    outline: none !important;
    text-overflow: ellipsis;
    ${transition()};
    ${appearanceNone()};
    ${(props) =>
      props.disabled
        ? customPlaceholder(`
            color: #DDE4EA;
          `)
        : `&:hover {
            border-color: #a5b3c5;
          };
          ${customPlaceholder(`
            color: ${props['data-theme'] === 'dark' ? 'white' : '#A5B3C5'};
            font-weight: normal;
          `)};
        `}
    &:focus {
      border-color: ${variables.accent};
    }

      //${(props) => (props.error ? `border-color: ${variables.red}!important` : '')};
  `,
  Area: styled.textarea`
    display: block;
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    color: ${variables.black};
    padding: 15px;
    border-radius: 2px;
    border: 1px solid ${(props) => (props.disabled ? '#DDE4EA' : variables.border)};
    background-color: ${(props) => props.background || 'white'};
    resize: none;
    font-family: ${variables.font};
    ::-webkit-scrollbar {
      width: 20px;
      border-width: 3px;
      background-color: transparent;
    }
    ::placeholder {
      color: #a5b3c5;
      font-weight: 500;
      font-size: 14px;
      line-height: 140%;
      font-family: ${variables.font};
    }

    ::-webkit-scrollbar-track-piece {
      background-color: ${variables.grey};
      border-color: ${(props) => props.background || 'white'};
      border-width: 2px 9.5px 2px 9.5px;
      border-style: solid;
      border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 9px;
      background-color: ${variables.grey};
      border-color: ${(props) => props.background || 'white'};
      border-style: solid;
      border-width: 1px 8px 1px 8px;
    }
    outline: none !important;
    ${appearanceNone()};
    ${(props) =>
      props.disabled
        ? customPlaceholder(`
            color: #DDE4EA;
          `)
        : `&:hover {
            border-color: #a5b3c5;
          };
          ${customPlaceholder(`
            color: #A5B3C5;
            font-weight: normal;
          `)};
        `}
    &:focus {
      border-color: ${variables.accent};
    }
    ${(props) => (props.error ? `border-color: ${variables.red}!important` : '')};
  `,
  HintWrapper: styled.div`
    position: fixed;
    z-index: 3;
    display: block;
    top: 0;
    left: 0;
    ${setSize('1px')};
  `,
  Hint: styled.div`
    position: absolute;
    top: 35px;
    right: -27px;
    font-size: 12px;
    border-radius: 2px;
    display: block;
    width: 112px;
    line-height: 1.36;
    background-color: ${variables.black};
    color: #dde4ea;
    padding: 10px 15px;
    &::before {
      content: '';
      position: absolute;
      bottom: 100%;
      right: 20px;
      width: 0;
      height: 0;
      display: block;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 8px solid ${variables.black};
    }
  `,
  Error: styled.span`
    position: absolute;
    top: 100%;
    left: 0;
    transform: translateY(1px);
    font-size: 12px;
    display: flex;
    align-items: center;
    width: 100%;
    color: ${variables.red};
    padding: 0 4px;
    ${(props) => props.changeErrorUi && `padding-left: 1px;`}
    &::before {
      content: '!';
      margin-right: 8px;
      background-color: ${variables.red};
      color: white;
      font-size: 7px;
      font-weight: 900;
      border-radius: 50%;
      ${setSize('10px')};
      ${flexCenter()};
    }
  `,
  IconContainer: styled.div`
    position: absolute;
    top: 40px;
    right: 15px;
    width: 18px;
    height: 18px;
    cursor: pointer;
    display: grid;
    align-content: center;
  `,

  PasswordIconHidden: styled(PasswordEyeCrossed)``,
  PasswordIconVisible: styled(PasswordEye)``,
  Phone: styled.input`
    display: block;
    width: 100%;
    height: ${(props) => (props.height ? props.height : '100%')};
    font-size: ${(props) => (props.height ? '10px' : '14px')};
    font-weight: 500;
    color: ${(props) => (props['data-theme'] === 'dark' ? 'white' : variables.black)};
    padding: 0 40px 0 15px;

    border-radius: 2px;
    border: 1px solid
      ${(props) =>
        props.disabled ? '#DDE4EA' : props['data-theme'] === 'dark' ? 'rgba(255, 255, 255, 0.2)' : variables.border};
    cursor: ${(props) => (props.disabled ? 'pointer' : 'initial')};
    background-color: ${(props) =>
      props['data-theme'] === 'dark' ? `rgba(255, 255, 255, 0.1)` : props.background || 'white'};
    outline: none !important;
    ${appearanceNone()};
    ${(props) =>
      props.disabled
        ? customPlaceholder(`
            color: #DDE4EA;
          `)
        : `&:hover {
            border-color: #a5b3c5;
          };
          ${customPlaceholder(`
            color: ${props['data-theme'] === 'dark' ? 'white' : '#A5B3C5'};
            font-weight: normal;
          `)};
        `}
    &:focus {
      border-color: ${variables.accent};
    }
    ${(props) => (props.error ? `border-color: ${variables.red}!important` : '')};
  `,
};

export { Style };
