import * as React from 'react';

const PasswordEyeCrossed = React.memo(() => {
  return (
    <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9 3.50039C11.8425 3.50039 14.3775 5.09789 15.615 7.62539C15.1725 8.54039 14.55 9.32789 13.8075 9.96539L14.865 11.0229C15.9075 10.1004 16.7325 8.94539 17.25 7.62539C15.9525 4.33289 12.75 2.00039 9 2.00039C8.0475 2.00039 7.1325 2.15039 6.27 2.42789L7.5075 3.66539C7.995 3.56789 8.49 3.50039 9 3.50039ZM8.1975 4.35539L9.75 5.90789C10.1775 6.09539 10.5225 6.44039 10.71 6.86789L12.2625 8.42039C12.3225 8.16539 12.3675 7.89539 12.3675 7.61789C12.375 5.75789 10.86 4.25039 9 4.25039C8.7225 4.25039 8.46 4.28789 8.1975 4.35539ZM1.5075 1.90289L3.5175 3.91289C2.295 4.87289 1.3275 6.14789 0.75 7.62539C2.0475 10.9179 5.25 13.2504 9 13.2504C10.14 13.2504 11.235 13.0329 12.24 12.6354L14.805 15.2004L15.8625 14.1429L2.565 0.837891L1.5075 1.90289ZM7.1325 7.52789L9.09 9.48539C9.06 9.49289 9.03 9.50039 9 9.50039C7.965 9.50039 7.125 8.66039 7.125 7.62539C7.125 7.58789 7.1325 7.56539 7.1325 7.52789V7.52789ZM4.5825 4.97789L5.895 6.29039C5.7225 6.70289 5.625 7.15289 5.625 7.62539C5.625 9.48539 7.14 11.0004 9 11.0004C9.4725 11.0004 9.9225 10.9029 10.3275 10.7304L11.0625 11.4654C10.4025 11.6454 9.7125 11.7504 9 11.7504C6.1575 11.7504 3.6225 10.1529 2.385 7.62539C2.91 6.55289 3.675 5.66789 4.5825 4.97789Z"
        fill="#A5B3C5"
      />
    </svg>
  );
});
export { PasswordEyeCrossed };
